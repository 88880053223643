<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Cities</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Cities
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="importData = !importData" class="mt-4 mx-3 btn btn-primary" :loading="loading" style="color: #fff">
                    Import City
                  </v-btn>
                  <v-btn  @click="createCity()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-row class="mb-3" v-if="importData">
              <v-col cols="12" md="8">
                <v-file-input
                    label="Import"
                    prepend-icon=""
                    prepend-inner-icon="fas fa-file"
                    accept=".csv"
                    v-model="file"
                    :error="$v.file.$error"
                    outlined
                    dense
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                    class="btn btn-primary"
                    @click="importCity"
                    :loading="loading"
                    dark
                >Import</v-btn>
              </v-col>
            </v-row>
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                  <v-col cols="12" sm="6" md="4">
                <v-text-field label="Lookup by Name, Short Name" v-on:keyup.enter="searchCity" clearable v-model="search.info" outlined dense></v-text-field>
              </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="4">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchCity"
                      dense clearable
                  ></v-select>
                </v-col>
                  <v-col cols="12" sm="6" md="4">
                <v-btn
                    :loading="isLoading"
                    @click="searchCity"
                    class="mt-1 btn btn-primary"
                >
                  <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')"  @click="sortBy('name')"><strong>Name</strong></th>
                  <th><strong>State Name</strong>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>ACTION</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index">
                    <td class="px-2">
                      <a @click="editCity(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{item.name }}
                      </a>
                    </td>

                    <td class="px-2">
                      <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item.state}}</span>
                    </td>

                    <td class="px-2">
                   <span class="badge"
                         v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                   >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editCity(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteState(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCity"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number  :disabled="isLoading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllCity"></create-or-update>
    </div>
  </v-app>
</template>
<script>
  import CityService from "@/services/country/city/CityService";
  const cityService = new CityService();
  import CreateOrUpdate from "./CreateOrUpdate";
  import { required } from "vuelidate/lib/validators";
  export default {
  components:{
    CreateOrUpdate,
  },
    validations: {
        file: {required},
    },
    data() {
    return{
      importData: false,
      isLoading: false,
      loading: false,
      file: null,
      search:{
        info:'',
        state_id: '',
        is_active: 1,
      },
      cities:[],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],

    }
  },
  methods: {
    importCity() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append('file', this.file)
        cityService.import(this.stateId, formData).then((response) => {
          if(response.data.status == "OK") {
            this.file = null;
            this.importData = false;
            this.loading = false;
            this.getAllCity();
            this.$v.$reset();
            this.$snotify.success("Import Successfully");
          } else {
            this.$snotify.error(response.data.message);
          }
        }).catch(() => {
          this.loading = false;
        })
      }


    },

    getAllCity(){
      this.isLoading = true;
      cityService
          .paginate(this.search)
          .then(response => {
            this.cities=response.data.data;
            this.isLoading=false;
          })
          .catch((err) => {
          });
    },
    searchCity(){
      this.getAllCity();
    },
    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createOrUpdateState(stateId){
      if(stateId==null)
        this.$router.push({
          name:"state-create"
        });
      else
        this.$router.push({
          name:'state-edit',
          params:{stateId:stateId}
        })
    },
    createCity(){
      this.$refs['create-or-update'].createCity();
    },
    editCity(item){
      this.$refs['create-or-update'].editCity(item);
    },
    deleteState(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            cityService
                .delete(item)
                .then((response) => {
                  this.getAllCity();
                  this.$snotify.success("City Info Successfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  mounted() {
    this.search.state_id = this.stateId;
    this.getAllCity();
  },
  computed:{
    sortedItems () {
      const list = this.cities.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    },
    stateId () {
      return this.$route.params.state_id;
    },
    countryId () {
      return this.$route.params.country_id;
    },
  },
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>