<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} City</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="City Name*"
                  outlined
                  dense :error="$v.city.name.$error"
                  v-model="city.name">
              </v-text-field>
              <span class="text-danger" v-if="$v.city.name.$error">City Name is Required</span>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="City Prefix"
                  outlined
                  dense
                  v-model="city.prefix">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" >
              <v-autocomplete
                  v-model="city.state_id"
                  :items="states"
                  filled
                  outlined
                  dense color="blue-grey lighten-2"
                  label="Select State"
                  item-text="name"
                  item-value="id">

              </v-autocomplete>

            </v-col>
            <v-col cols="12" md="12" >
              <v-switch
                  label="Status"
                  v-model="city.is_active"
                  outlined
                  dense
               >
              </v-switch>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
  import StateService from "@/services/country/state/StateService";
  const stateService = new StateService();
  import CityService from "@/services/country/city/CityService";
  const cityService = new CityService
import {required} from "vuelidate/lib/validators";
export default {
  validations:{
    city:{
      name:{required},
    },
  },
  data() {
    return {
      title: 'Create',
      dialog: false,
      edit: false,
      loading: false,
      states:[],
      errors:[],
      city:{
        name:'',
        prefix:'',
        state_id: '',
        is_active: true,
     },
    }
  },
  methods: {
    getStates(){
      stateService
          .all(this.countryId)
          .then((response) => {
            this.states = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.city={
        name:'',
        prefix:'',
        state_id: '',
        is_active: true,
      };
      this.city.state_id = this.stateId;
    },
    createCity() {

      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editCity(item) {
      this.openDialog();
      this.edit = true;
      this.city = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      cityService
          .create(this.city)
          .then(response => {
            this.$snotify.success("City created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      cityService
          .update(this.city.id, this.city)
          .then(response => {
            this.$snotify.success("City updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },

    cityDetail() {
      if(this.cityId != null || this.cityId !=undefined ){
        cityService
            .show(this.cityId)
            .then(response=> {
              this.state = response.data.state;
            })
      }
    }
  },
  mounted() {
    this.getStates();
    this.cityDetail();
    if (this.stateId)
      this.city.state_id = this.stateId;
  },
  computed:{
    stateId () {
      return this.$route.params.state_id;
    },
    countryId () {
      return this.$route.params.country_id;
    },
    titleText(){
      if(this.stateId!=null || this.stateId!=undefined)
        return this.title="Edit";
      else
        return this.title="Create New"
    }
  }
}
</script>